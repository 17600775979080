import { InMemoryCache, gql, makeVar } from "@apollo/client";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";

import { OrderStatusArray } from "../utils/constants";

import fragmentMatcher from "./generated/fragment-matchers";
import { TypedTypePolicies } from "./generated/helpers";
import { OrderStatus } from "./generated/react-apollo";

export const loggedInVar = makeVar<boolean>(!!localStorage.getItem("token"));
export const pageVar = makeVar<number>(0);
export const statusFilterVar = makeVar<OrderStatus[]>(
  OrderStatusArray.slice(1, -1)
);

export const typeDefs = gql`
  extend type Query {
    loggedIn: Boolean!
  }
`;

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      loggedIn: {
        read() {
          return loggedInVar();
        },
      },
    },
  },
  Order: {
    fields: {
      reservation: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
};

export const cache = new InMemoryCache({
  possibleTypes: fragmentMatcher.possibleTypes,
  typePolicies,
});

export const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(localStorage),
  maxSize: 1024 * 1024 * 20, // 4MB
});

export default cache;
export type Cache = typeof cache;

export const generateCache = () => {
  return new InMemoryCache({
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies,
  });
};

export const generatePersistedCache = () => {
  const cache = new InMemoryCache({
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies,
  });

  const persistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(localStorage),
    maxSize: 1024 * 1024 * 20, // 4MB
  });

  return { cache, persistor };
};
