import { Alert, Button, Snackbar, TextField } from "@mui/material";
import * as React from "react";

import { loggedInVar } from "../apollo/cache";

const PASSWORD = "gain2022!";

const Auth = () => {
  const [value, setValue] = React.useState("");
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        login(value);
        event.preventDefault();
        // callMyFunction();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [value]);

  const login = (value: string) => {
    if (value === PASSWORD) {
      loggedInVar(true);
      localStorage.setItem("token", "gain2022!");
    } else {
      setOpen(true);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className=" max-w-lg mx-auto py-40">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          잘못된 비밀번호입니다.
        </Alert>
      </Snackbar>
      <div className="flex flex-col items-center gap-6">
        <div className="text-center">
          <h2>닥터차 타이어 커머스</h2>
          <h3>관리자 콘솔</h3>
        </div>
        <TextField
          type="password"
          label="비밀번호"
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          size="large"
          type="submit"
          onClick={() => {
            login(value);
          }}
        >
          로그인
        </Button>
      </div>
    </div>
  );
};

export default Auth;
