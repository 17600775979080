let env: Env | undefined = undefined;
class Env {
  public API_URL = "";
  public WS_URL = "";

  private constructor() {
    const env = process.env;
    if (
      !env.REACT_APP_GRAPHQL_HTTP_ENDPOINT ||
      !env.REACT_APP_GRAPHQL_WS_ENDPOINT
    ) {
      throw new Error("Enviromnents is not setted!");
    }
    this.API_URL = env.REACT_APP_GRAPHQL_HTTP_ENDPOINT;
    this.WS_URL = env.REACT_APP_GRAPHQL_WS_ENDPOINT;
  }

  public static getInstance() {
    if (env) {
      return env;
    }
    env = new Env();
    return env;
  }
}

export default Env.getInstance();
