import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date scalar type represented by YYYY-MM-DD formatted string. */
  Date: any;
  /** DateTime scalar type represented by UNIX timestamp in milliseconds. */
  DateTime: any;
  /** Time scalar type represented by hh:mm:ss formatted string. */
  Time: any;
};

/** 주문 취소 정보 */
export type CancelOrderData = {
  /** 취소 사유 */
  reason: Scalars['String'];
};

/** 주문 취소 에러 */
export type CancelOrderError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

export type CancelOrderFail = {
  __typename?: 'CancelOrderFail';
  /** 주문 취소 에러 */
  error: CancelOrderError;
};

export type CancelOrderResponse = CancelOrderFail | CancelOrderSuccess;

export type CancelOrderSuccess = {
  __typename?: 'CancelOrderSuccess';
  /** 취소된 주문 */
  order: Order;
};

export type CompletePaymentArgs = {
  /** 아임포트 결제 ID */
  importId: Scalars['String'];
  /** 주문 ID */
  orderId: Scalars['String'];
};

export type CompletePaymentSuccess = {
  __typename?: 'CompletePaymentSuccess';
  /** 결제 완료된 주문 */
  order: Order;
};

/** 좌표 */
export type Coordinates = {
  __typename?: 'Coordinates';
  /** 위도 */
  latitude: Scalars['Float'];
  /** 경도 */
  longitude: Scalars['Float'];
};

export type CoordinatesInput = {
  /** 위도 */
  latitude: Scalars['Float'];
  /** 경도 */
  longitude: Scalars['Float'];
};

/** 주문 생성 요청 정보 */
export type CreateOrderData = {
  /** 주문자 정보 */
  customer: CreateOrderDataOrderCustomerData;
  /** 주문 상품 목록 */
  lines: Array<CreateOrderDataOrderLineData>;
  reservation: CreateOrderDataOrderReservationData;
  vehicle: CreateOrderDataOrderVehicleData;
};

/** 주문 생성 요청 주문자 정보 */
export type CreateOrderDataOrderCustomerData = {
  /** 성명 */
  name: Scalars['String'];
  phone: Scalars['String'];
};

/** 주문 생성 요청 주문 상품 정보 */
export type CreateOrderDataOrderLineData = {
  /** 상품 ID */
  productId: Scalars['ID'];
  /** 상품 수량 */
  quantity: Scalars['Int'];
};

/** 주문 생성 요청 장착 예약 정보 */
export type CreateOrderDataOrderReservationData = {
  /** 예약 시점 */
  at: Scalars['DateTime'];
  /** 추가 요청 사항 */
  extraRequest?: InputMaybe<Scalars['String']>;
  /** 예약 장착점 ID */
  shopId: Scalars['ID'];
};

/** 주문 생성 요청 장착 예약 차량 정보 */
export type CreateOrderDataOrderVehicleData = {
  brandId: Scalars['ID'];
  modelId: Scalars['ID'];
  plateNumber: Scalars['String'];
};

/** 주문 생성 에러 */
export type CreateOrderError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

export type CreateOrderFail = {
  __typename?: 'CreateOrderFail';
  error: CreateOrderError;
};

export type CreateOrderResponse = CreateOrderFail | CreateOrderSuccess;

export type CreateOrderSuccess = {
  __typename?: 'CreateOrderSuccess';
  /** 생성된 주문 */
  order: Order;
};

/** 날짜 범위 */
export type DateRangeInput = {
  /** 시작일 */
  from: Scalars['Date'];
  /** 종료일 */
  to: Scalars['Date'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** 주문을 취소합니다. */
  cancelOrder: CancelOrderResponse;
  completePayment?: Maybe<CompletePaymentSuccess>;
  createOrder: CreateOrderResponse;
  /** 주문의 예약 정보를 변경합니다. */
  updateOrderReservation: UpdateOrderReservationResponse;
  /** 주문 상태를 수정합니다. */
  updateOrderStatus: UpdateOrderStatusResponse;
};


export type MutationCancelOrderArgs = {
  data: CancelOrderData;
  orderId: Scalars['ID'];
};


export type MutationCompletePaymentArgs = {
  payment: CompletePaymentArgs;
};


export type MutationCreateOrderArgs = {
  data: CreateOrderData;
};


export type MutationUpdateOrderReservationArgs = {
  data: UpdateOrderReservationData;
  orderId: Scalars['ID'];
};


export type MutationUpdateOrderStatusArgs = {
  data: UpdateOrderStatusData;
  orderId: Scalars['ID'];
};

/** 주문 */
export type Order = {
  __typename?: 'Order';
  /** 주문 생성 시점 */
  createdAt: Scalars['DateTime'];
  /** 주문자 정보 */
  customer: OrderCustomer;
  /** ID */
  id: Scalars['ID'];
  /** 주문 상품 목록 */
  lines: Array<OrderLine>;
  /** 이름 */
  name: Scalars['String'];
  /** 주문번호 */
  no: Scalars['String'];
  /** 총 결제 금액 (KRW) */
  paymentAmount: Scalars['Int'];
  /** 취소 위약금 (KRW) */
  penaltyAmount?: Maybe<Scalars['Int']>;
  /** 예약 정보 */
  reservation: OrderReservation;
  /** 상태 */
  status: OrderStatus;
  /** 차량 정보 */
  vehicle: OrderVehicle;
  /** 판매자 */
  vendor: Vendor;
};

/** 주문자 정보 */
export type OrderCustomer = {
  __typename?: 'OrderCustomer';
  /** 주문자명 */
  name: Scalars['String'];
  /** 주문자 휴대전화번호 */
  phone: Scalars['String'];
};

/** 주문 상품 */
export type OrderLine = {
  __typename?: 'OrderLine';
  /** 상품 */
  product: Product;
  /** 수량 */
  quantity: Scalars['Int'];
  /** 개당 판매 가격 (KRW) */
  salePrice: Scalars['Int'];
};

/** 인증을 위한 주문자 정보 */
export type OrderQueryCustomerData = {
  /** 주문자명 */
  name: Scalars['String'];
  /** 주문자 휴대전화번호 */
  phone: Scalars['String'];
};

/** 주문 예약 정보 */
export type OrderReservation = {
  __typename?: 'OrderReservation';
  /** 예약 시점 */
  at: Scalars['DateTime'];
  /** 추가 요청 사항 */
  extraRequest?: Maybe<Scalars['String']>;
  /** 예약 장착점 */
  shop?: Maybe<Shop>;
};

/** 주문 상태 */
export enum OrderStatus {
  /** 장착 완료 */
  Completed = 'COMPLETED',
  /** 확정 */
  Confirmed = 'CONFIRMED',
  /** 고객 취소 */
  CustomerCancelled = 'CUSTOMER_CANCELLED',
  /** 배송 완료 */
  Delivered = 'DELIVERED',
  /** 배송 중 */
  Delivering = 'DELIVERING',
  /** 결제 대기 중 */
  PendingPayment = 'PENDING_PAYMENT',
  /** 접수 */
  Received = 'RECEIVED',
  /** 미결제 */
  Unpaid = 'UNPAID',
  /** 판매자 취소 */
  VendorCanclled = 'VENDOR_CANCLLED'
}

/** 주문 차량 정보 */
export type OrderVehicle = {
  __typename?: 'OrderVehicle';
  /** 제조사 */
  brand: VehicleBrand;
  /** 모델 */
  model: VehicleModel;
  /** 차량 번호 */
  plateNumber: Scalars['String'];
};

export type OrdersFilter = {
  /** 주문 상태 목록 */
  statuses?: InputMaybe<Array<OrderStatus>>;
};

/** 상품 */
export type Product = {
  /** ID */
  id: Scalars['ID'];
  /** 판매 가격 (KRW) */
  salePrice: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  loggedIn: Scalars['Boolean'];
  /** 주문 */
  order?: Maybe<Order>;
  /** 요청 판매자의 주문 목록. */
  orders: Array<Order>;
  reservableSlots: Array<ReservationSlot>;
  /** 장착점 */
  shop?: Maybe<Shop>;
  tireBrands?: Maybe<Array<TireBrand>>;
  /** 타이어 제품군 목록 */
  tireFamilies: Array<TireFamily>;
  /** 타이어 패턴 정보 */
  tireFamily: TireFamily;
  /** 모든 차량 제조사 목록. */
  vehicleBrands: Array<VehicleBrand>;
  /** 차량 제조사의 차량 모델 목록. */
  vehicleModels: Array<VehicleModel>;
  /** 차량번호의 차량 스펙 */
  vehicleSpec?: Maybe<VehicleSpec>;
  /** 판매자 목록 */
  vendors: Array<Vendor>;
};


export type QueryOrderArgs = {
  customer?: InputMaybe<OrderQueryCustomerData>;
  orderId: Scalars['ID'];
};


export type QueryOrdersArgs = {
  filter: OrdersFilter;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryReservableSlotsArgs = {
  coordinates: CoordinatesInput;
  dateRange: DateRangeInput;
  tireFamilyIds: Array<Scalars['ID']>;
};


export type QueryShopArgs = {
  shopId: Scalars['ID'];
};


export type QueryTireFamiliesArgs = {
  filter: TireFamiliesFilter;
  first?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<TireFamiliesSortOrder>;
};


export type QueryTireFamilyArgs = {
  tireFamilyId: Scalars['ID'];
};


export type QueryVehicleModelsArgs = {
  brandId: Scalars['ID'];
};


export type QueryVehicleSpecArgs = {
  ownerName: Scalars['String'];
  plateNumber: Scalars['String'];
};

/** 장착 예약 슬롯 */
export type ReservationSlot = {
  __typename?: 'ReservationSlot';
  /** 예약 날짜 */
  date: Scalars['Date'];
  /** 장착점 */
  shop: Shop;
  /** 주어진 좌표로부터 장착점까지의 거리 (km) */
  shopDistance: Scalars['Float'];
  /** 예약 시각 슬롯 목록 */
  timeSlots: Array<TimeSlot>;
};

/** 장착점 */
export type Shop = {
  __typename?: 'Shop';
  /** 주소 */
  address: Scalars['String'];
  /** 연락처 */
  contact?: Maybe<Scalars['String']>;
  /** 좌표 */
  coordinates: Coordinates;
  /** ID */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
  /** 썸네일 용 이미지 URL */
  thumbnailImageUrl?: Maybe<Scalars['String']>;
  /** 소속 판매자 */
  vendor: Vendor;
};

/** 시각 슬롯 */
export type TimeSlot = {
  __typename?: 'TimeSlot';
  /** 선택 가능 여부 */
  available: Scalars['Boolean'];
  /** 시각 */
  time: Scalars['Time'];
};

export type Tire = Product & {
  __typename?: 'Tire';
  /** 제품군 */
  family: TireFamily;
  /** ID */
  id: Scalars['ID'];
  /** 공장도가 (KRW) */
  retailPrice: Scalars['Int'];
  /** 판매 가격 (KRW) */
  salePrice: Scalars['Int'];
  /** 사이즈 */
  size: TireSize;
  /** 판매처 */
  vendor: Vendor;
};

/** 타이어 제조사 */
export type TireBrand = {
  __typename?: 'TireBrand';
  /** ID */
  id: Scalars['ID'];
  /** 로고 이미지 URL */
  logoImageUrl: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
};

export type TireFamiliesFilter = {
  /** 계절 목록 */
  seasons?: InputMaybe<Array<TireSeason>>;
  /** 타이어 제조사 ID 목록 */
  tireBrandIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 타이어 제품군이 하나라도 포함해야할 타이어 사이즈 목록 */
  tireSizes?: InputMaybe<Array<TireSizeInput>>;
  /** 판매자 ID 목록 */
  vendorIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** 타이어 제품군 목록 정렬 조건 */
export enum TireFamiliesSortOrder {
  /** 최소 판매 가격 오름차순 */
  MinimumSalePriceAsc = 'MINIMUM_SALE_PRICE_ASC',
  /** 최소 판매 가격 내림차순 */
  MinimumSalePriceDesc = 'MINIMUM_SALE_PRICE_DESC'
}

/** 타이어 제품군 */
export type TireFamily = {
  __typename?: 'TireFamily';
  /** 제조사 */
  brand: TireBrand;
  /** ID */
  id: Scalars['ID'];
  /** 이미지 URL 목록 */
  imageUrls: Array<Scalars['String']>;
  /** 최소 판매 가격 (KRW) */
  minimumSalePrice: Scalars['Int'];
  /** 이름 */
  name: Scalars['String'];
  /** 런플랫 여부 */
  runflat: Scalars['Boolean'];
  /** 계절 */
  season: TireSeason;
  /** 타이어 사이즈 목록 */
  sizes: Array<TireSize>;
  /** 타이어 목록 */
  tires: Array<Tire>;
  /** 판매자 목록 */
  vendors: Array<Vendor>;
};

/** 타이어 계절성 */
export enum TireSeason {
  /** 사계절 */
  AllSeasons = 'ALL_SEASONS',
  /** 여름 */
  Summer = 'SUMMER',
  /** 겨울 */
  Winter = 'WINTER'
}

export type TireSize = {
  __typename?: 'TireSize';
  /** 편평비 */
  aspectRatio: Scalars['Int'];
  /** 휠 직경 */
  wheelDiameter: Scalars['Int'];
  /** 폭 */
  width: Scalars['Int'];
};

/** 타이어 사이즈 입력값 */
export type TireSizeInput = {
  /** 편평비 */
  aspectRatio: Scalars['Int'];
  /** 휠 직경 */
  wheelDiameter: Scalars['Int'];
  /** 폭 */
  width: Scalars['Int'];
};

/** 차량 타이어 사이즈 */
export type TireSizes = {
  __typename?: 'TireSizes';
  /** 앞 타이어 사이즈 */
  front: TireSize;
  /** 뒷 타이어 사이즈 */
  rear: TireSize;
};

/** 타이어 장착비 정책 */
export type TireWagePolicy = {
  __typename?: 'TireWagePolicy';
  /** 조건 */
  condition: Scalars['String'];
  /** 본당 장착비 (KRW) */
  wage: Scalars['Int'];
};

/** 주문이 취소 불가 상태인 경우. */
export type UncancellableStatusError = CancelOrderError & {
  __typename?: 'UncancellableStatusError';
  /** 에러 메세지 */
  message: Scalars['String'];
};

export type UpdateOrderReservationData = {
  /** 변경할 예약 시점 */
  at?: InputMaybe<Scalars['DateTime']>;
  /** 변경할 장착점 ID */
  shopId?: InputMaybe<Scalars['ID']>;
};

/** 주문 예약 정보 변경 에러 */
export type UpdateOrderReservationErrorResponse = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

/** 주문 예약 정보 변경 실패 response */
export type UpdateOrderReservationFail = {
  __typename?: 'UpdateOrderReservationFail';
  /** 주문 예약 정보 변경 에러 */
  error: UpdateOrderReservationErrorResponse;
};

/** 주문 예약 정보 변경 response */
export type UpdateOrderReservationResponse = UpdateOrderReservationFail | UpdateOrderReservationSuccess;

/** 주문 예약 정보 변경 성공 response */
export type UpdateOrderReservationSuccess = {
  __typename?: 'UpdateOrderReservationSuccess';
  /** 예약 정보가 변경된 주문 */
  order: Order;
};

export type UpdateOrderStatusData = {
  /** 취소 위약금 (KRW) */
  penaltyAmount?: InputMaybe<Scalars['Int']>;
  /** 변경할 주문 상태 */
  status: OrderStatus;
};

/** 주문 상태 변경 에러 */
export type UpdateOrderStatusError = {
  /** 에러 메세지 */
  message: Scalars['String'];
};

export type UpdateOrderStatusFail = {
  __typename?: 'UpdateOrderStatusFail';
  error: UpdateOrderStatusError;
};

export type UpdateOrderStatusResponse = UpdateOrderStatusFail | UpdateOrderStatusSuccess;

export type UpdateOrderStatusSuccess = {
  __typename?: 'UpdateOrderStatusSuccess';
  /** 상태가 변경된 주문 */
  order: Order;
};

export type VehicleBrand = {
  __typename?: 'VehicleBrand';
  /** 국내 제조사 여부 */
  domestic: Scalars['Boolean'];
  /** ID */
  id: Scalars['ID'];
  /** 로고 이미지 URL */
  logoImageUrl: Scalars['String'];
  /** 차량 모델 목록 */
  models: Array<VehicleModel>;
  /** 이름 */
  name: Scalars['String'];
};

export type VehicleGrade = {
  __typename?: 'VehicleGrade';
  /** ID */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
  /** 차량 세부모델 */
  submodel: VehicleSubmodel;
};

export type VehicleModel = {
  __typename?: 'VehicleModel';
  /** 차량 제조사 */
  brand: VehicleBrand;
  /** ID */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
};

/** 차량 스펙 */
export type VehicleSpec = {
  __typename?: 'VehicleSpec';
  /** 차량 제조사 */
  brand: VehicleBrand;
  /** 차량 모델 */
  model: VehicleModel;
  /** 차량 세부등급 목록. 세부등급을 특정할 수 있을 경우 길이 1의 배열을 반환. */
  subgrades: Array<VehicleSubgrade>;
  /** 차량 세부모델 */
  submodel: VehicleSubmodel;
};

export type VehicleSubgrade = {
  __typename?: 'VehicleSubgrade';
  /** ID */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
  /** 차량 등급 */
  submodel: VehicleGrade;
  /** 타이어 사이즈 */
  tireSizes: TireSizes;
};

export type VehicleSubmodel = {
  __typename?: 'VehicleSubmodel';
  /** 차량 등급 목록 */
  grades: Array<VehicleGrade>;
  /** ID */
  id: Scalars['ID'];
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** 차량 모델 */
  model: VehicleModel;
  /** 이름 */
  name: Scalars['String'];
};

/** 판매처 */
export type Vendor = {
  __typename?: 'Vendor';
  /** ID */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
  /** 장착비 정책 목록 */
  wagePolicies?: Maybe<Array<TireWagePolicy>>;
};

export type AppQueryVariables = Exact<{ [key: string]: never; }>;


export type AppQuery = { __typename?: 'Query', loggedIn: boolean };

export type HomeQueryVariables = Exact<{
  filter: OrdersFilter;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type HomeQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: string, no: string, status: OrderStatus, createdAt: any, penaltyAmount?: number | null, paymentAmount: number, customer: { __typename?: 'OrderCustomer', name: string, phone: string }, vehicle: { __typename?: 'OrderVehicle', plateNumber: string, brand: { __typename?: 'VehicleBrand', id: string, name: string }, model: { __typename?: 'VehicleModel', id: string, name: string } }, reservation: { __typename?: 'OrderReservation', at: any, extraRequest?: string | null, shop?: { __typename?: 'Shop', id: string, name: string, address: string, contact?: string | null, thumbnailImageUrl?: string | null, vendor: { __typename?: 'Vendor', id: string, name: string } } | null }, lines: Array<{ __typename?: 'OrderLine', salePrice: number, quantity: number, product: { __typename?: 'Tire', id: string, salePrice: number, size: { __typename?: 'TireSize', width: number, aspectRatio: number, wheelDiameter: number }, family: { __typename?: 'TireFamily', id: string, name: string, imageUrls: Array<string>, brand: { __typename?: 'TireBrand', logoImageUrl: string, name: string } } } }> }> };

export type UpdateOrderStatusMutationVariables = Exact<{
  orderId: Scalars['ID'];
  data: UpdateOrderStatusData;
}>;


export type UpdateOrderStatusMutation = { __typename?: 'Mutation', updateOrderStatus: { __typename?: 'UpdateOrderStatusFail', error: never } | { __typename?: 'UpdateOrderStatusSuccess', order: { __typename?: 'Order', id: string, status: OrderStatus, penaltyAmount?: number | null } } };

export type UpdateOrderReservationMutationVariables = Exact<{
  orderId: Scalars['ID'];
  data: UpdateOrderReservationData;
}>;


export type UpdateOrderReservationMutation = { __typename?: 'Mutation', updateOrderReservation: { __typename?: 'UpdateOrderReservationFail', error: never } | { __typename?: 'UpdateOrderReservationSuccess', order: { __typename?: 'Order', id: string, reservation: { __typename?: 'OrderReservation', at: any } } } };


export const AppDocument = gql`
    query App {
  loggedIn @client
}
    `;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppQuery(baseOptions?: Apollo.QueryHookOptions<AppQuery, AppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppQuery, AppQueryVariables>(AppDocument, options);
      }
export function useAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppQuery, AppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppQuery, AppQueryVariables>(AppDocument, options);
        }
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export type AppQueryResult = Apollo.QueryResult<AppQuery, AppQueryVariables>;
export const HomeDocument = gql`
    query Home($filter: OrdersFilter!, $page: Int!, $pageSize: Int!) {
  orders(filter: $filter, page: $page, pageSize: $pageSize) {
    id
    no
    status
    createdAt
    penaltyAmount
    paymentAmount
    customer {
      name
      phone
    }
    vehicle {
      plateNumber
      brand {
        id
        name
      }
      model {
        id
        name
      }
    }
    reservation {
      at
      extraRequest
      shop {
        id
        name
        address
        contact
        thumbnailImageUrl
        vendor {
          id
          name
        }
      }
    }
    lines {
      salePrice
      quantity
      product {
        ... on Tire {
          id
          salePrice
          size {
            width
            aspectRatio
            wheelDiameter
          }
          family {
            id
            name
            imageUrls
            brand {
              logoImageUrl
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useHomeQuery(baseOptions: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const UpdateOrderStatusDocument = gql`
    mutation updateOrderStatus($orderId: ID!, $data: UpdateOrderStatusData!) {
  updateOrderStatus(orderId: $orderId, data: $data) {
    ... on UpdateOrderStatusSuccess {
      order {
        id
        status
        penaltyAmount
      }
    }
    ... on UpdateOrderStatusFail {
      error {
        message
      }
    }
  }
}
    `;
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(UpdateOrderStatusDocument, options);
      }
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;
export const UpdateOrderReservationDocument = gql`
    mutation updateOrderReservation($orderId: ID!, $data: UpdateOrderReservationData!) {
  updateOrderReservation(orderId: $orderId, data: $data) {
    ... on UpdateOrderReservationSuccess {
      order {
        id
        reservation {
          at
        }
      }
    }
    ... on UpdateOrderReservationFail {
      error {
        message
      }
    }
  }
}
    `;
export type UpdateOrderReservationMutationFn = Apollo.MutationFunction<UpdateOrderReservationMutation, UpdateOrderReservationMutationVariables>;

/**
 * __useUpdateOrderReservationMutation__
 *
 * To run a mutation, you first call `useUpdateOrderReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderReservationMutation, { data, loading, error }] = useUpdateOrderReservationMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrderReservationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderReservationMutation, UpdateOrderReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderReservationMutation, UpdateOrderReservationMutationVariables>(UpdateOrderReservationDocument, options);
      }
export type UpdateOrderReservationMutationHookResult = ReturnType<typeof useUpdateOrderReservationMutation>;
export type UpdateOrderReservationMutationResult = Apollo.MutationResult<UpdateOrderReservationMutation>;
export type UpdateOrderReservationMutationOptions = Apollo.BaseMutationOptions<UpdateOrderReservationMutation, UpdateOrderReservationMutationVariables>;