import { TireSize } from "../apollo/generated/react-apollo";

export const tireSizeStringifier = (tireSize: TireSize) => {
  let value = "";
  value += tireSize.width.toString();
  if (tireSize.width >= 100) value += "/";
  if (tireSize.aspectRatio !== null) {
    value += tireSize.aspectRatio.toString();
    if (tireSize.aspectRatio >= 30 && tireSize.aspectRatio <= 125) value += "R";
    if (tireSize.wheelDiameter !== null) {
      value += tireSize.wheelDiameter.toString();
    }
  }
  return value;
};

export const phoneNumberFormatter = (phoneNumber?: string | null) => {
  let result = "";

  if (!phoneNumber) {
    return;
  }

  if (phoneNumber.length < 8) {
    result = phoneNumber.replace(/^(\d{3})(\d+)$/, "$1-$2");
  } else if (phoneNumber.length < 10) {
    result = phoneNumber.replace(/^(\d{3})(\d{3,4})(\d+)$/, "$1-$2-$3");
  } else {
    result = phoneNumber.replace(/^(\d{3})(\d{3,4})(\d{4})$/, "$1-$2-$3");
  }
  return result;
};
