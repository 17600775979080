
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CancelOrderError": [
      "UncancellableStatusError"
    ],
    "CancelOrderResponse": [
      "CancelOrderFail",
      "CancelOrderSuccess"
    ],
    "CreateOrderError": [],
    "CreateOrderResponse": [
      "CreateOrderFail",
      "CreateOrderSuccess"
    ],
    "Product": [
      "Tire"
    ],
    "UpdateOrderReservationErrorResponse": [],
    "UpdateOrderReservationResponse": [
      "UpdateOrderReservationFail",
      "UpdateOrderReservationSuccess"
    ],
    "UpdateOrderStatusError": [],
    "UpdateOrderStatusResponse": [
      "UpdateOrderStatusFail",
      "UpdateOrderStatusSuccess"
    ]
  }
};
      export default result;
    