import createTheme from "@mui/material/styles/createTheme";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#007aff",
    },
  },
  typography: {
    fontFamily: '"Pretendard", "Helvetica","Arial", sans-serif',
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: "primary",
        fullWidth: true,
        variant: "contained",
      },
    },
    MuiDivider: {
      defaultProps: {
        variant: "fullWidth",
      },
      styleOverrides: {
        root: {
          margin: "12px 0px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        color: "secondary",
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
  },
});
