import { ApolloClient, from, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import env from "../utils/env";

import { cache, typeDefs } from "./cache";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      "x-password": token || "",
    },
  };
});

const httpLink = new HttpLink({
  uri: env.API_URL,
});

const link = from([errorLink, authLink, httpLink]);

export const client = new ApolloClient({
  link,
  cache,
  typeDefs,
});

export const logout = async () => {
  localStorage.removeItem("token");
};
