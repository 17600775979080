import { gql } from "@apollo/client";
import { Route, Routes } from "react-router-dom";

import { useAppQuery } from "./apollo/generated/react-apollo";
import Auth from "./routes/Auth";
import Home from "./routes/Home";

gql`
  query App {
    loggedIn @client
  }
`;

const App = () => {
  const { data } = useAppQuery();

  return (
    <Routes>
      {data?.loggedIn ? (
        <Route path="/" element={<Home />} />
      ) : (
        <Route path="/" element={<Auth />} />
      )}
    </Routes>
  );
};

export default App;
