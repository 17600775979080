import { gql, useReactiveVar } from "@apollo/client";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { loggedInVar, pageVar, statusFilterVar } from "../apollo/cache";
import {
  OrderStatus,
  useHomeQuery,
  useUpdateOrderReservationMutation,
  useUpdateOrderStatusMutation,
} from "../apollo/generated/react-apollo";
import { OrderStatusArray, OrderStatusData } from "../utils/constants";
import { phoneNumberFormatter, tireSizeStringifier } from "../utils/formatters";

gql`
  query Home($filter: OrdersFilter!, $page: Int!, $pageSize: Int!) {
    orders(filter: $filter, page: $page, pageSize: $pageSize) {
      id
      no
      status
      createdAt
      penaltyAmount
      paymentAmount
      customer {
        name
        phone
      }
      vehicle {
        plateNumber
        brand {
          id
          name
        }
        model {
          id
          name
        }
      }
      reservation {
        at
        extraRequest
        shop {
          id
          name
          address
          contact
          thumbnailImageUrl
          vendor {
            id
            name
          }
        }
      }
      lines {
        salePrice
        quantity
        product {
          ... on Tire {
            id
            salePrice
            size {
              width
              aspectRatio
              wheelDiameter
            }
            family {
              id
              name
              imageUrls
              brand {
                logoImageUrl
                name
              }
            }
          }
        }
      }
    }
  }

  mutation updateOrderStatus($orderId: ID!, $data: UpdateOrderStatusData!) {
    updateOrderStatus(orderId: $orderId, data: $data) {
      ... on UpdateOrderStatusSuccess {
        order {
          id
          status
          penaltyAmount
        }
      }
      ... on UpdateOrderStatusFail {
        error {
          message
        }
      }
    }
  }

  mutation updateOrderReservation(
    $orderId: ID!
    $data: UpdateOrderReservationData!
  ) {
    updateOrderReservation(orderId: $orderId, data: $data) {
      ... on UpdateOrderReservationSuccess {
        order {
          id
          reservation {
            at
          }
        }
      }
      ... on UpdateOrderReservationFail {
        error {
          message
        }
      }
    }
  }
`;

const PAGE_SIZE = 10;

type FilterOption = {
  label: string;
  id: string;
  value: OrderStatus[];
};

const FILTER_OPTIONS: FilterOption[] = [
  {
    label: "전체",
    id: "all",
    value: OrderStatusArray.slice(1, -1),
  },
  {
    label: "접수 목록",
    id: "received",
    value: [OrderStatus.Received],
  },
  {
    label: "접수 후~장착 전 목록",
    id: "confirmed",
    value: [
      OrderStatus.Confirmed,
      OrderStatus.Delivering,
      OrderStatus.Delivered,
    ],
  },
  {
    label: "과거 내역",
    id: "completed",
    value: [
      OrderStatus.Completed,
      OrderStatus.VendorCanclled,
      OrderStatus.CustomerCancelled,
    ],
  },
  {
    label: "결제 대기중 목록",
    id: "pendingPayment",
    value: [OrderStatus.PendingPayment],
  },
];

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    filter: "all",
  });
  const filter = searchParams.get("filter");

  const [statusChangeModalopen, setStatusChangeModalOpen] =
    React.useState(false);

  const [reservationModalOpen, setReservationModalOpen] = React.useState(false);
  const [selectedOrderId, setSelectedOrderId] = React.useState<
    string | undefined
  >(undefined);
  const [selectedOrderStatus, setSelectedOrderStatus] =
    React.useState<OrderStatus>(OrderStatus.Received);
  const [selectedOrderReservationAt, setSelectedOrderReservationAt] =
    React.useState<Date>();
  const [penaltyAmount, setPenaltyAmount] = React.useState<
    number | undefined
  >();

  const page = useReactiveVar(pageVar);
  const statuses = useReactiveVar(statusFilterVar);

  React.useEffect(() => {
    const filterValue = FILTER_OPTIONS.find((option) => option.id === filter);
    pageVar(0);
    if (filterValue) {
      statusFilterVar(filterValue.value);
    }
  }, [filter]);

  const { data, loading, error, refetch } = useHomeQuery({
    variables: {
      filter: statuses.length > 0 ? { statuses } : {},
      page,
      pageSize: PAGE_SIZE,
    },
    onError: (error) => {
      alert(error.message);
    },
  });

  const [updateOrderStatusMutation] = useUpdateOrderStatusMutation({
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      alert(error.message);
    },
  });

  const [updateOrderReservationMutation] = useUpdateOrderReservationMutation({
    onError: (error) => {
      alert(error.message);
    },
  });

  const handleStatusChangeModalOpen = (orderId: string) => {
    const order = data?.orders.find((order) => order.id === orderId);
    if (order?.status) {
      setSelectedOrderStatus(order.status);
    }
    setPenaltyAmount(order?.penaltyAmount || undefined);
    setSelectedOrderId(orderId);
    setStatusChangeModalOpen(true);
  };
  const handleStatusChangeModalClose = () => setStatusChangeModalOpen(false);

  const handleReservationModalOpen = (orderId: string) => {
    const reservation = data?.orders.find(
      (order) => order.id === orderId
    )?.reservation;
    if (reservation) {
      setSelectedOrderReservationAt(reservation.at);
    }
    setSelectedOrderId(orderId);
    setReservationModalOpen(true);
  };
  const handleReservationModalClose = () => setReservationModalOpen(false);

  const selectedOrder = data?.orders.find(
    (order) => order.id === selectedOrderId
  );

  const selectedOrderStatusIndex = OrderStatusArray.findIndex(
    (stat) => stat === selectedOrder?.status
  );

  return (
    <>
      <Modal open={reservationModalOpen} onClose={handleReservationModalClose}>
        <div className="absolute top-1/2 left-1/2 w-96 bg-white rounded-md p-8 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex justify-end items-center">
            <a onClick={handleReservationModalClose} className="inline">
              <CloseOutlinedIcon />
            </a>
          </div>
          <div className="flex flex-col w-full gap-6">
            <h3 className="text-bold text-center">예약시간 변경</h3>
            <TextField
              color="primary"
              disabled
              label="장착매장 (변경불가)"
              value={selectedOrder?.reservation.shop?.name}
            />
            <DateTimePicker
              label="예약시간 설정"
              value={selectedOrderReservationAt}
              onChange={(newDate: Date | null) => {
                newDate && setSelectedOrderReservationAt(newDate);
              }}
              disableMaskedInput
              renderInput={(params) => (
                <TextField color="primary" {...params} />
              )}
            />
            <div>
              <span className="font-bold">
                {selectedOrder?.customer.name}(
                {phoneNumberFormatter(selectedOrder?.customer.phone)},{" "}
                {selectedOrder?.vehicle.brand.name}{" "}
                {selectedOrder?.vehicle.model.name})
              </span>
              님의 예약일자가{" "}
              <span className="text-blue-500 font-bold">
                {dayjs(selectedOrderReservationAt).format("YYYY.MM.DD A hh:mm")}
              </span>
              로 변경됩니다.
            </div>
            <Button
              size="large"
              onClick={async () => {
                if (selectedOrderReservationAt && selectedOrderId) {
                  await updateOrderReservationMutation({
                    variables: {
                      orderId: selectedOrderId,
                      data: {
                        at: selectedOrderReservationAt,
                      },
                    },
                  });
                }
                handleReservationModalClose();
                alert("예약시간이 변경되었습니다.");
              }}
              disabled={
                selectedOrder?.reservation.at === selectedOrderReservationAt
              }
            >
              예약 시간 업데이트
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={statusChangeModalopen}
        onClose={handleStatusChangeModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-1/2 left-1/2 w-96 bg-white rounded-md p-8 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex justify-end items-center">
            <a onClick={handleStatusChangeModalClose} className="inline">
              <CloseOutlinedIcon />
            </a>
          </div>
          <div className="flex flex-col w-full gap-6">
            <h3 className="text-bold text-center">주문 상태 변경</h3>
            <TextField
              color="primary"
              label="위약금"
              type="number"
              value={penaltyAmount}
              onChange={(e) => {
                if (e.target.value === "") {
                  setPenaltyAmount(undefined);
                } else if (parseInt(e.target.value)) {
                  setPenaltyAmount(parseInt(e.target.value));
                }
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="주문상태">주문상태</InputLabel>
              <Select
                labelId="주문상태"
                id="주문상태"
                label="주문상태"
                value={selectedOrderStatus}
                onChange={(e) =>
                  setSelectedOrderStatus(e.target.value as OrderStatus)
                }
              >
                {OrderStatusArray.slice(selectedOrderStatusIndex, -1).map(
                  (status) => (
                    <MenuItem key={status} value={status}>
                      {OrderStatusData[status].description}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <Button
              size="large"
              disabled={
                selectedOrderStatus === selectedOrder?.status &&
                selectedOrder?.penaltyAmount === penaltyAmount
              }
              onClick={async () => {
                if (selectedOrderId) {
                  await updateOrderStatusMutation({
                    variables: {
                      orderId: selectedOrderId,
                      data: {
                        penaltyAmount: penaltyAmount || 0,
                        status: selectedOrderStatus,
                      },
                    },
                  });
                } else {
                  alert("예약 시간 업데이트를 실패했습니다.");
                }
                handleStatusChangeModalClose();
                alert("주문 상태 변경이 완료되었습니다.");
              }}
            >
              변경하기
            </Button>
          </div>
        </div>
      </Modal>
      <header className="sticky top-0 px-4 py-3 bg-blue-700 shadow-md z-40 flex flex-row justify-between">
        <h4 className="text-white">닥터차 타이어 커머스 관리자</h4>
        <button
          className="text-white"
          onClick={() => {
            loggedInVar(false);
            localStorage.removeItem("token");
          }}
        >
          로그아웃
        </button>
      </header>
      <main className="mx-auto max-w-4xl py-4">
        <div className="flex flex-row items-center justify-between">
          <h3>주문 목록</h3>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            defaultValue={[]}
            value={filter}
            onChange={(e) => {
              const option = FILTER_OPTIONS.find(
                (option) => option.id === e.target.value
              );
              if (option) {
                setSearchParams({ filter: option.id });
              }
            }}
            name="radio-buttons-group"
          >
            {FILTER_OPTIONS.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.id}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </div>
        <div className="flex flex-col py-4 gap-4">
          {error && <h3 className="text-center py-16">{error.message}</h3>}
          {loading && <h3 className="text-center py-16">Loading...</h3>}
          {data?.orders.map((order) => {
            return (
              <div
                className="bg-white shadow-lg p-6 rounded-2xl border"
                key={order.id}
              >
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row items-center gap-2">
                    <Chip
                      color={OrderStatusData[order.status].color}
                      size="medium"
                      label={OrderStatusData[order.status].description}
                    />
                    <div className="text-sm text-gray-500">
                      주문번호: {order.no} | 주문일시:{" "}
                      {dayjs(order.createdAt).format("YYYY.MM.DD")}{" "}
                      {order.penaltyAmount
                        ? `| 위약금: ${order.penaltyAmount.toLocaleString()}원`
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row py-2 justify-between">
                  <div>
                    <h3>
                      {order.customer.name}{" "}
                      {phoneNumberFormatter(order.customer.phone)}
                    </h3>
                    <div className="text-gray-500 font-medium pt-1">
                      <span className="text-gray-400">차종 정보:</span>{" "}
                      {order.vehicle.brand.name} {order.vehicle.model.name}
                    </div>
                    {order.reservation.extraRequest && (
                      <div className="text-gray-500 font-medium">
                        <span className="text-gray-400">요청 사항:</span>{" "}
                        {order.reservation.extraRequest}
                      </div>
                    )}
                  </div>
                  {order.status !== OrderStatus.PendingPayment && (
                    <div className="flex flex-row gap-2 items-center">
                      {OrderStatusArray.findIndex(
                        (stat) => stat === order.status
                      ) < 5 && (
                        <Button
                          fullWidth={false}
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            handleStatusChangeModalOpen(order.id);
                          }}
                          startIcon={<EditIcon />}
                        >
                          주문 상태 변경
                        </Button>
                      )}

                      {order.status === OrderStatus.Received && (
                        <Button
                          fullWidth={false}
                          onClick={async () => {
                            await updateOrderStatusMutation({
                              variables: {
                                orderId: order.id,
                                data: {
                                  status: OrderStatus.Confirmed,
                                },
                              },
                            });
                          }}
                          startIcon={<CheckCircle />}
                        >
                          주문 확정
                        </Button>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex flex-row gap-4 pt-2">
                  <div className="flex-1">
                    <div className="pb-1">결제정보</div>
                    <hr />
                    {order.lines.map((line, index) => (
                      <div key={index} className="flex flex-row gap-4 py-2">
                        <div className="bg-gray-100 rounded-lg p-1 relative">
                          <img
                            className="w-28"
                            src={line.product.family.imageUrls[0]}
                            alt={line.product.family.name}
                          />
                          <img
                            className="h-8 pb-2 absolute bottom-0 bg-gray-100 bg-opacity-70 left-1/2 transform -translate-x-1/2"
                            src={line.product.family.brand.logoImageUrl}
                            alt={line.product.family.brand.name}
                          />
                        </div>
                        <div className="flex-1 flex-col flex justify-between">
                          <div>
                            <h4>{line.product.family.name}</h4>
                            {line.product.size && (
                              <p className="text-gray-500 text-lg">
                                {tireSizeStringifier(line.product.size)}
                              </p>
                            )}
                          </div>
                          <div className="py-2 text-lg font-medium">
                            <span className="text-blue-500">
                              {line.salePrice.toLocaleString()}원
                            </span>{" "}
                            x{line.quantity}
                          </div>
                        </div>
                      </div>
                    ))}
                    <hr className="border-dashed" />
                    {OrderStatusArray.findIndex(
                      (stat) => stat === order.status
                    ) >= 6 ? (
                      <div>
                        <div className="flex flex-row justify-between items-center pt-1">
                          <div className="text-gray-600 line-through">
                            총 결제 금액
                          </div>
                          <h4 className="text-gray-500 line-through">
                            {order.paymentAmount.toLocaleString()}원
                          </h4>
                        </div>
                        <div className="flex flex-row justify-between items-center pt-1">
                          <div className="text-gray-600 text-sm">위약금</div>
                          <h4 className="text-red-500 text-sm">
                            {(order.penaltyAmount || 0).toLocaleString()}원
                          </h4>
                        </div>
                        <div className="flex flex-row justify-between items-center pt-1">
                          <div className="text-gray-600">총 환불액</div>
                          <h4 className="text-gray-500">
                            {(
                              order.paymentAmount - (order.penaltyAmount || 0)
                            ).toLocaleString()}
                            원
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row justify-between items-center pt-2">
                        <div className="text-gray-600">총 결제 금액</div>
                        <h4 className="text-blue-500">
                          {order.paymentAmount.toLocaleString()}원
                        </h4>
                      </div>
                    )}
                  </div>
                  <div className="flex-1">
                    <div className="pb-1">예약정보</div>
                    <hr className="pb-2" />
                    <div className="flex flex-row justify-between items-center bg-green-100 rounded-md h-12 px-3">
                      <div className="text-md text-green-900 flex flex-row items-center">
                        <AccessTimeIcon className="mr-2" />
                        {dayjs(order.reservation.at).format(
                          "YYYY.MM.DD (dd) A hh:mm"
                        )}
                      </div>
                      {OrderStatusArray.findIndex(
                        (stat) => stat === order.status
                      ) < 5 && (
                        <Button
                          variant="text"
                          size="small"
                          color={"success"}
                          fullWidth={false}
                          onClick={() => {
                            handleReservationModalOpen(order.id);
                          }}
                        >
                          예약시간 변경
                        </Button>
                      )}
                    </div>
                    <div className="flex flex-row justify-between pt-2">
                      <div>
                        <h4 className="font-bold pb-1">
                          {order.reservation.shop?.name}
                        </h4>
                        <div className="text-sm pb-1">
                          {order.reservation.shop?.address}
                        </div>
                        <div>
                          {phoneNumberFormatter(
                            order.reservation.shop?.contact
                          )}
                        </div>
                      </div>
                      {order.reservation.shop?.thumbnailImageUrl && (
                        <img
                          className="rounded-lg w-32 mt-2 object-cover"
                          src={order.reservation.shop?.thumbnailImageUrl}
                          alt={order.reservation.shop?.name}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center py-4">
          <Pagination
            disabled={loading}
            page={page + 1}
            onChange={(e, index) => {
              pageVar(index - 1);
            }}
            size="large"
            count={10}
          />
        </div>
      </main>
      <footer className="h-24 bg-gray-200"></footer>
    </>
  );
};

export default Home;
