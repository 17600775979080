import { ChipProps } from "@mui/material";

import { OrderStatus } from "../apollo/generated/react-apollo";

export const OrderStatusData: Record<
  OrderStatus,
  { description: string; id: string; color: ChipProps["color"] }
> = {
  [OrderStatus.PendingPayment]: {
    description: "결제 대기 중 ",
    id: "000",
    color: "default",
  },
  [OrderStatus.Received]: {
    description: "접수",
    id: "100",
    color: "default",
  },
  [OrderStatus.Confirmed]: {
    description: "확정",
    id: "101",
    color: "info",
  },
  [OrderStatus.Delivering]: {
    description: "배송 중",
    id: "200",
    color: "info",
  },
  [OrderStatus.Delivered]: {
    description: "배송 완료",
    id: "201",
    color: "info",
  },
  [OrderStatus.Completed]: {
    description: "장착 완료",
    id: "300",
    color: "success",
  },
  [OrderStatus.CustomerCancelled]: {
    description: "고객 취소",
    id: "900",
    color: "error",
  },
  [OrderStatus.VendorCanclled]: {
    description: "판매자 취소",
    id: "901",
    color: "error",
  },
  [OrderStatus.Unpaid]: {
    description: "미결제",
    id: "999",
    color: "error",
  },
};

export const OrderStatusArray = Object.keys(OrderStatusData) as OrderStatus[];
